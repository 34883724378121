import PropTypes from "prop-types";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainMenu from "./MainMenu";
import "../css/App.scss";

const Header = ({ siteTitle }) => {
  return (
    <div className="nav-wrapper">
      <Container>
        <Row>
          <Col>
            <MainMenu />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: "",
};

export default Header;
