import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={6} className="mt-3">
            <p>This site is a fan site of the beverage Dr Pepper and not affiliated with "Dr Pepper/Seven Up, Inc."</p>
          </Col>

          <Col md={6} className="text-md-right mt-3">
            <div className="d-flex flex-column justify-content-end">
              <div className="">
                © {new Date().getFullYear()}, <a href="https://drpepperaddiction.com">Dr Pepper Addiction</a>{" "}
              </div>
              <div className="">
                <Link to="/affiliate-disclosure/">Affiliate Disclosure</Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
