import React from "react";
import { Link } from "gatsby";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { GatsbyImage } from "gatsby-plugin-image";

const MainMenu = () => {
  let logo;
  const logoHtml = logo ? (
    <Navbar.Brand as="div">
      <Link aria-label="Link to home" className="d-inline" to="/">
        <GatsbyImage image={logo} alt="Logo" />
      </Link>
    </Navbar.Brand>
  ) : (
    ""
  );
  return (
    <div className="header-menu">
      <Navbar expand="lg">
        {logoHtml}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav defaultActiveKey="/" className="mr-auto">
            <Link className="nav-link d-inline p-2-md" to="/">
              Home
            </Link>
            <NavDropdown title="Products" id="basic-nav-dropdown">
              <Link className="dropdown-item" to="/drpepper/">
                Dr Pepper
              </Link>
              <Link className="dropdown-item" to="/drpepper-diet/">
                Dr Pepper Diet
              </Link>
              <Link className="dropdown-item" to="/drpepper-zero/">
                Dr Pepper Zero
              </Link>
              <Link className="dropdown-item" to="/drpepper-cream-soda/">
                Dr Pepper Cream Soda
              </Link>

              <Link className="dropdown-item" to="/drpepper-cream-soda-zero/">
                Dr Pepper Cream Soda Zero
              </Link>

              <Link className="dropdown-item" to="/drpepper-cherry/">
                Dr Pepper Cherry
              </Link>

              <Link className="dropdown-item" to="/drpepper-cherry-zero/">
                Dr Pepper Cherry Zero
              </Link>
              <Link className="dropdown-item" to="/drpepper-cherry-vanilla/">
                Dr Pepper Cherry Vanilla
              </Link>
            </NavDropdown>
            <Link className="nav-link d-inline p-2-md" to="/signs/">
              Signs!
            </Link>
            <Link className="nav-link d-inline p-2-md" to="/risks/">
              Risks
            </Link>
            <Link className="nav-link d-inline p-2-md" to="/get-help/">
              Get Help!
            </Link>
            {
              <Link className="nav-link d-inline p-2-md" to="/content/">
                Content
              </Link>
            }
            <Link className="nav-link d-inline p-2-md" to="/about/">
              About
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default MainMenu;
